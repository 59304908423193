import HTTP, { apiHandler } from "@services/http.service";
import { AxiosRequestConfig } from "axios";
import { IncomingMessage } from "http";
import { NextApiRequestCookies } from "next/dist/server/api-utils";
export type ServerRequest = IncomingMessage & {
    cookies: NextApiRequestCookies;
} & {
    accessToken: string | null;
    locale: string | null;
    defaultLocale: string | null;
    locales: null | string[];
    isBlocked: boolean;
};

export const postNotFoundPage = async (url: string): Promise<void> => {
    const config: AxiosRequestConfig = {
        method: "post",
        url: "/api/not-found",
        data: {
            url,
        },
    };

    await apiHandler(() => HTTP.server(config));
};
