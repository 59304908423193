import React, { useEffect } from "react";
import Image from "next/legacy/image";
import LinkButton from "@components/shared/form/linkButton";
import { postNotFoundPage } from "src/api/notFoundPage";
import { GetStaticPropsContext } from "next";
import initialCall from "@helpers/initial-call";
import { translate } from "@helpers/translationObjectFormatter";
import { TranslationsObject } from "@genericTypes/sharedTypes";
import { useQuery } from "@tanstack/react-query";

const NotFound = () => {
    const { data: translations } = useQuery<TranslationsObject>([
        "translations",
    ]);

    useEffect(() => {
        void postNotFoundPage(window.location.pathname);
    }, []);

    return (
        <div className="max-w-5xl flex flex-col items-center m-auto gap-10 my-3 mb-12">
            <div className="relative sm:w-[600px] w-[300px] h-[200px] sm:h-[400px]">
                <Image
                    src="/404.png"
                    alt="404"
                    objectFit="contain"
                    layout="fill"
                />
            </div>
            <div className="text-center flex flex-col gap-2">
                <h1 className="text-2xl sm:text-4xl font-bold text-[#032554] m-0">
                    {translate(translations, "several_notFoundPage-mainTiltle")}
                </h1>
                <p className="text-gray-400 m-0">
                    {translate(
                        translations,
                        "several_notFoundPage-mainDescription",
                    )}
                </p>
            </div>
            <div className="flex flex-col sm:flex-row ">
                <LinkButton className="min-w-[150px]" href="/">
                    {translate(translations, "several_notFoundPage-mainButton")}
                </LinkButton>
                <LinkButton
                    className="min-w-[300px] sm:min-w-[200px] !bg-white !text-[#1593f0]"
                    href="/categories"
                >
                    {translate(
                        translations,
                        "several_notFoundPage-secondButton",
                    )}
                </LinkButton>
            </div>
        </div>
    );
};
export const getStaticProps = async (ctx: GetStaticPropsContext) => {
    return await initialCall("404", ctx);
};

export default NotFound;
